.denfit-top {
    background: #e1d26a;
}
.app-features {
    padding: 100px;
    .feature-list {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(3, 1fr);
        .feature-item {
            margin: auto;
            .feature-image {
                img {
                    width: 350px;
                }
                
            }
            .feature-label {
                color: #1a1a1a;
                font-weight: 400;
                font-size: 16px;
                &:nth-child(2n+1) {
                    margin-bottom: 20px;
                }
                &:nth-child(2n) {
                    margin-top: 20px;
                }
                div {
                    color: #1a1a1a;
                    font-size: 29px;
                    font-weight: 300;
                    font-style: normal;
                    letter-spacing: 1.02px;
                }
            }
        }
    }
}

.denfitweek-screens {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 100px;
}