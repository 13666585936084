.susans-app-top {
    background-color: #b6cc09;
    display: flex;
    justify-content: space-around;
    .app-detail {
        padding: 150px 100px;
        width: 30%;
        .app-title {
            color: #fdfdfe;
            font-size: 48px;
            font-weight: 400;
            margin-bottom: 30px;
        }
        .app-description {
            width: 50%;
            color: #000000;
            font-size: 22px;
            font-weight: 300;
        }
        .app-links {
            color: #333333;
            font-size: 16px;
            font-weight: 300;
            .app-link {
                color: #fff;
                font-weight: 600;
            }
            .social-media-icons {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .susans-app-image {
        background: url(/img/susans-samosas.png) center no-repeat;
        background-size: cover;
        width: 70%;
        // text-align: right;
        // width: 35%;
        // img {
            //     width: 500px;
            // }
        .app-stack {
             display: flex;
             flex-direction: column;
             justify-content: center;
            padding: 0 50px;
            text-align: left;
            width: 40%;
            height: 100%;
            margin-left: auto;
            background-color: rgba(182, 204, 9, 0.7)
        }
    }
}
.marketing-section {
    padding: 0 100px;
    .marketing-video {
        text-align: center  ;
        background-color: #fdede5;
        .video-title {
            font-size: 30px;
            color: #000;
            padding: 50px 0;
        }
        .video-footer {
            font-size: 20px;
            padding-bottom: 50px;
        }
    }
}
.poster-section {
    padding: 0 100px;
    display: flex;
    flex-direction: row;
    .poster-label {
        text-align: center;
        font-size: 20px;
        margin-top: 30px;
    }
    .poster1 {
        img {
            width: 80%;
        }
    }
    .poster2 {
        justify-self: end;
        margin-top: 100px;
        text-align: right;
        img {
            width: 90%;
        }
    }
}

.susans-samosas-screens {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 100px;
    img {
        width: 90%;
    }
}