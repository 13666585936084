.pitchn-top {
    background: #81ce8b;
    .pitchn-phone {
        img {
            width: 400px;
        }
    }
}
.marketing {
    padding: 100px;
    .marketing-posters {
        display: flex;
        justify-content: space-between;
        .poster-images {
            // width: 60%;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(4, 1fr);
            gap: 40px;
            img {
                // width: 10%;
            }
            div:first-child {
                grid-row: 1 / 3;
                grid-column: 1 / 4;
            }
            div:nth-child(2) {
                grid-row: 3 / 5;
                grid-column: 2 / 5;
            }
            div:last-child {
                grid-row: 2 / 4;
                grid-column: 5 / 8;
            }
        }
        .video-images {
            // width: 60%;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(4, 1fr);
            gap: 40px;
            img {
                // width: 10%;
            }
            div:first-child {
                grid-row: 1 / 3;
                grid-column: 2 / 5;
            }
            div:nth-child(2) {
                grid-row: 3 / 5;
                grid-column: 2 / 5;
            }
            div:last-child {
                grid-row: 2 / 4;
                grid-column: 6 / 9;
            }
        }
        .marketing-description {
            width: 35%;
            margin-top: 100px;
            color: #1a1a1a;
            .marketing-label {
                font-size: 16px;
                font-weight: 400;   
            }
            .marketing-title {
                font-size: 29px;
                font-weight: 300;
                letter-spacing: 1.02px;
                margin-top: 10px;
            }
            .marketing-content {
                font-size: 18px;
                font-weight: 400;
                margin-top: 20px;
            }
        }
    }
}
.pitchn-screens {
    padding: 100px;
    display: flex;
    justify-content: space-evenly;
    div {
        position: relative;
        padding: 100px;
        border-radius: 20px;
        background-color: #b3e2b9;
        .phone-screen {
            position: relative;
            z-index: 9;
        }
        .screen-dots-1 {
            position: absolute;
            z-index: 1;
            bottom: 50px;
            right: 50px;
        }
        .screen-dots-2 {
            position: absolute;
            transform: rotate(90deg);
            z-index: 1;
            top: -50px;
            right: 125px;
        }
        .screen-dots-3 {
            transform: rotate(90deg);
            position: absolute;
            z-index: 1;
            top: 100px;
            left: 125px;
        }
    }
}