.social-ping-top {
    .app-detail .app-title {
        color: #ec1940;
    }
    .app-image{
        text-align: center;
        img {
            width: 70%;
        }
    } 
}
.marketing-section {
    padding: 0 100px;
    .logo-design {
        text-align: center  ;
        background-color: #fff;
        img {
            width: 500px;
        }
        .video-title {
            font-size: 30px;
            color: #000;
            padding-bottom: 70px;
        }
        .video-footer {
            font-size: 20px;
            padding-top: 50px;
        }
    }
}

.social-ping-screens {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 0;
    img {
        margin-right: 40px;
        width: 300px;
    }
}