.App-header {
    // position: unset !important;
}
.app-page {
    text-align: left;
}
.app-top {
    display: flex;
    justify-content: space-around;
    padding: 150px 100px;
    .app-detail {
        width: 30%;
        .app-title {
            color: #fdfdfe;
            font-size: 48px;
            font-weight: 400;
            margin-bottom: 30px;
        }
        .app-description {
            width: 65%;
            color: #000000;
            font-size: 22px;
            font-weight: 300;
        }
        .app-links {
            color: #333333;
            font-size: 16px;
            font-weight: 300;
            .app-link {
                color: #fff;
                font-weight: 600;
            }
            .social-media-icons {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .app-image {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 35%;
        img {
            width: 500px;
        }
    }
}
.app-stack {
    text-align: left;
    width: 35%;
    margin-left: 50px;
    font-size: 22px;
    strong {
        letter-spacing: 0.5px;
    }
    .tech-libraries {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        .stack-items {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 10px;
            div img {
                opacity: 1;
            }
        }
    }
}
.app-showcase {
    padding: 100px;
    .showcase-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        padding: 50px 0;
        .showcase-image {
            margin: auto;
            text-align: center;
            .img-mod-1 {
                width: 80%;
            }
            .img-mod-2 {
                width: 70%;
            }
            .img-mod-3 {
                margin-top: 20px;
                margin-left: 70px;
            }
            .img-mod-4 {
                width: 50%;
                margin-right: 20px;
            }
            .img-mod-5 {
                width: 300px;
                margin-right: 40px;
            }
        }
        .center-images {
            display: flex;
            align-items: center;
        }
        .showcase-detail {
            padding: 100px;
            color: #333333;
            font-size: 18px;
            font-weight: 400;
            display: flex;
            align-self: center;
            .showcase-detail-content {
                width: 70%;
                margin: auto;
            }
            .showcase-title {
                color: #1a1a1a;
                font-size: 16px;
                font-weight: 400;   
            }
            .showcase-label {
                color: #000;
                font-size: 30px;
                font-weight: 400;
                margin-bottom: 30px;
            }
        }
    }
}
.small-divider {
    width: 60px;
    height: 6px;
    margin: 20px 0;
    background-color: #fdfdfe;
}
.small-divider-red {
    background-color: #e3183d;
}