div[class^="home-"] {
    display: flex;
    height: 100vh;
}
div[class^="top-"] {
    width: 50%;
}
.top-left {
    color: #1a1a1a;
    font-size: 33px;
    text-align: left;
    position: relative;
    .project-description {
        width: 55%;
        margin: 30% auto;
    }
    .section-label {
        position: absolute;
        background-color: #81CE8A;
        right: 0px;
        bottom: -10%;
        font-size: 22px;
        color: #fff;
        padding: 15px;
        border-radius: 15px 0 0 15px;
        animation: slide-up 0.7s cubic-bezier(.29,.47,.55,1) 0.5s forwards;
        @keyframes slide-up {
            from{
                bottom: -10%;
            }
            to {
                bottom: 50%;
            }
        }
    }
}
.top-right {
    
}
.project-image {
    text-align: center;
}
.home-top {
    .top-left {
        background: #B3E2B9;
    }
    .top-right {
        background: #81CE8A;
        .project-image {
            img {
                margin: 20% auto;
                width: 350px;
            }
        }
    }
}
.home-mid {
    .top-left {
        background: #9574D9;
    }
    .top-right {
        background: #7E6ED0;
        .project-image {
            img {
                margin: 20% auto;
                width: 90%;
                transform: rotate(-25deg);
            }
        }
    }
}
.home-bottom {
    .top-left {
        background: #EBE19C;
    }
    .top-right {
        background: #fff;
        .project-image {
            img {
                margin: 20% auto;
                width:70%;
            }
        }
    }
}
.home {
    // position: relative;
}
.projects-collapse {
    position: fixed;
    width: 40%;
    text-align: left;
    background: #fff;
    opacity: 0.8;
    bottom: 0;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-radius: 30px 30px 0 0;
}